<template>
  <div class="entity-view-wrapper">
    <div
      ref="entityViewTabs"
      class="entity-view-tabs-wrapper flex m-fullwidth overflow-auto white-space-nowrap"
    >
      <div
        class="tab tab-entity"
        :class="{
          'tab-active tab-entity-active': activeTab === 'overview',
        }"
        @click="handleActiveTab('overview')"
      >
        <div class="entity-tab-text">
          Overview
        </div>
      </div>
      <div
        v-show="
          formData.data &&
            formData.data.regulatoryCompliance &&
            formData.data.regulatoryCompliance.length
        "
        class="tab tab-entity"
        :class="{
          'tab-active tab-entity-active':
            activeTab === 'regulatory-compliance',
        }"
        @click="handleActiveTab('regulatory-compliance')"
      >
        <div class="entity-tab-text">
          Regulatory Compliance
        </div>
      </div>
      <div
        v-show="
          formData.data &&
            formData.data.banks &&
            formData.data.banks.length
        "
        class="tab tab-entity"
        :class="{ 'tab-active tab-entity-active': activeTab === 'banks' }"
        @click="handleActiveTab('banks')"
      >
        <div class="entity-tab-text">
          Banks
        </div>
      </div>
      <div
        v-show="
          formData.data &&
            formData.data.paymentSystems &&
            formData.data.paymentSystems.length
        "
        class="tab tab-entity"
        :class="{
          'tab-active tab-entity-active': activeTab === 'payment-systems',
        }"
        @click="handleActiveTab('payment-systems')"
      >
        <div class="entity-tab-text">
          Payment Service
        </div>
      </div>
      <div
        v-show="
          (formData.data &&
            formData.data.contacts &&
            formData.data.contacts.length) ||
            (formData.data &&
              formData.data.contactsDepartments &&
              formData.data.contactsDepartments.length)
        "
        class="tab tab-entity"
        :class="{
          'tab-active tab-entity-active': activeTab === 'contacts',
        }"
        @click="handleActiveTab('contacts')"
      >
        <div class="entity-tab-text">
          Contacts
        </div>
      </div>
    </div>
    <div
      v-show="loading"
      class="flex align-center justify-center full-height"
    >
      <gl-loader />
    </div>
    <div v-show="!loading">
      <div
        v-show="!Object.keys(formData).length"
        class="flex align-center justify-center entity-view-content-wrapper"
      >
        <div class="entity-empty__wrapper">
          No data...
        </div>
      </div>

      <div v-show="Object.keys(formData).length">
        <div
          v-show="activeTab === 'overview'"
          class="entity-view-content-wrapper flex"
        >
          <EntityOverviewView
            :form-data="formData"
            :loading-screenshoots="loadingScreenshoots"
            @setImageModalData="
              (data, i) => $emit('setImageModalData', data, i)
            "
            @showViewImageModal="$emit('showViewImageModal')"
          />
        </div>
        <div v-show="activeTab === 'regulatory-compliance'">
          <div
            v-if="
              formData.data &&
                formData.data.regulatoryCompliance &&
                formData.data.regulatoryCompliance.length
            "
            class="entity-view-content-wrapper"
          >
            <EntityRegulatoryComplianceTable
              class="regulatory-compliance-table"
              :table-data="formData.data.regulatoryCompliance"
            />
          </div>
          <div
            v-else
            class="entity-view-content-wrapper flex align-center justify-center bold fs-14"
          >
            You currently have no regulation information.
          </div>
        </div>
        <div v-show="activeTab === 'banks'">
          <div
            v-if="
              formData.data && formData.data.banks && formData.data.banks.length
            "
            class="entity-view-content-wrapper"
          >
            <EntityBanksTable 
              class="banks-table" 
              :table-data="formData.data.banks" 
            />
          </div>
          <div
            v-else
            class="entity-view-content-wrapper flex align-center justify-center bold fs-14"
          >
            You currently have no banks.
          </div>
        </div>
        <div v-show="activeTab === 'payment-systems'">
          <div
            v-if="
              formData.data &&
                formData.data.paymentSystems &&
                formData.data.paymentSystems.length
            "
            class="entity-view-content-wrapper"
          >
            <EntityPaymentSystemsTable
              class="payments-systems-table"
              :table-data="formData.data.paymentSystems"
            />
          </div>
          <div
            v-else
            class="entity-view-content-wrapper flex align-center justify-center bold fs-14"
          >
            You currently have no payment service.
          </div>
        </div>
        <div
          v-show="activeTab === 'contacts'"
          class="entity-view-content-wrapper"
        >
          <div class="entity-view-overview-wrapper fullwidth contacts-people-departments-wrap">
            <div class="fs-16 bold-600 pa-4 pb-3">
              People
            </div>
            <div
              v-if="
                formData.data &&
                  formData.data.contacts &&
                  formData.data.contacts.length
              "
            >
              <EntityContactsTable 
                class="contacts-people-table" 
                :table-data="formData.data.contacts" 
              />
            </div>
            <div
              v-else
              class="entity-view-content-contract-department-wrapper flex align-center justify-center bold fs-14"
            >
              You currently have no contacts.
            </div>
            <hr class="grey-f-5-hr">
            <div class="fs-16 bold-600 pa-4 pb-3">
              Departments
            </div>
            <div
              v-if="
                formData.data &&
                  formData.data.contactsDepartments &&
                  formData.data.contactsDepartments.length
              "
            >
              <EntityContactsDepartmentsTable
                class="contacts-departments-table" 
                :table-data="formData.data.contactsDepartments"
              />
            </div>
            <div
              v-else
              class="entity-view-content-contract-department-wrapper flex align-center justify-center bold fs-14"
            >
              You currently have no Departments.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//Components
import GlLoader from '@/components/gl-loader'
import EntityOverviewView from '@/pages/entity/components/EntityOverviewView.vue'
import EntityContactsTable from '@/pages/entity/components/EntityContactsTable.vue'
import EntityBanksTable from '@/pages/entity/components/EntityBanksTable.vue'
import EntityRegulatoryComplianceTable from '@/pages/entity/components/EntityRegulatoryComplianceTable.vue'
import EntityPaymentSystemsTable from '@/pages/entity/components/EntityPaymentSystemsTable.vue'
import EntityContactsDepartmentsTable from '@/pages/entity/components/EntityContactsDepartmentsTable.vue'
// Utils
import { capitalizeFirstLetter } from '@/utils/text-formatter'
import { isValidURL } from '@/utils/text-formatter'
import { scrollToSelector } from '@/utils/scroll-to'
// Vuex
import { mapActions } from 'vuex'

export default {
  components: {
    GlLoader,
    EntityContactsTable,
    EntityBanksTable,
    EntityRegulatoryComplianceTable,
    EntityOverviewView,
    EntityPaymentSystemsTable,
    EntityContactsDepartmentsTable,
  },
  data() {
    return {
      formData: {},
      loading: false,
      loadingScreenshoots: false,
      activeTab: 'overview',
    }
  },
  computed: {},
  watch: {
    $route: {
      handler() {
        this.activeTab = 'overview'
        this.loadEntity()
      },
      immediate: true,
    },
  },

  methods: {
    ...mapActions('entity', ['getEntityById']),
    capitalizeFirstLetter,
    isValidURL,
    scrollToSelector,
    async loadEntity() {
      this.loading = true
      await this.getEntityById({
        ids: this.$route.params.entityId,
        screenshoots: 0,
      })
        .then(({ items }) => {
          if (items && items.length) {
            this.formData = items[0]
            this.loadEntityScreenshoots()
          } else {
            this.$toasted.global.error({ message: 'No data' })
          }
        })
        .then(() => {
          setTimeout(() => {
            this.scrollToSelector('.page-wrapper', '.entity-view-wrapper', 0)
          }, 500)
        })
        .catch(e => {
          this.$toasted.global.error({
            message: e?.response?.data?.data?.message || e,
          })
        })
        .finally(() => {
          this.loading = false
        })

      this.loading = false
    },
    async loadEntityScreenshoots() {
      this.loadingScreenshoots = true
      await this.getEntityById({
        ids: this.$route.params.entityId,
        screenshoots: 1,
      })
        .then(({ items }) => {
          if (items && items.length) {
            if (this.formData.data) {
              this.$set(
                this.formData.data,
                'screenshoots',
                items[0].data?.screenshoots,
              )
            }
          }
        })
        .catch(e => {
          this.$toasted.global.error({
            message: e?.response?.data?.data?.message || e,
          })
        })
        .finally(() => {
          this.loadingScreenshoots = false
        })
      this.loadingScreenshoots = false
    },
    handleActiveTab(name) {
      this.activeTab = name
      this.$nextTick(() => {
        this.$refs.entityViewTabs.scrollLeft =
          document.querySelector('.tab-active').offsetLeft - 495
      })
    },
  },
}
</script>

<style>
.entity-view-wrapper {
  display: flex;
  flex-direction: column;
  background: var(--white);
}
.tab-entity,
.tab-entity-active {
  font-size: 14px;
}

.tab-entity-active::after {
  height: 2px;
  bottom: -11px;
}

.entity-tab-text {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 220px;
}

.entity-view-tabs-wrapper {
  margin-bottom: 1px;
  padding-top: 10px;
  padding-bottom: 11px;
  border-bottom: 1px solid var(--cotton-grey-f-5);
}

.entity-view-content-wrapper {
  overflow-y: auto;
  height: calc(100vh - 150px);
}
.entity-view-content-wrapper::-webkit-scrollbar {
  width: 8px;
}
.entity-view-content-contract-department-wrapper {
  overflow-y: auto;
  height: calc(50vh - 280px);
}

.entity-value {
  font-weight: 500;
  color: var(--space-cadet);
  font-size: 14px;
}

@media (max-width: 767px) {
  .entity-view-content-wrapper {
    height: auto;
  }
}
</style>
