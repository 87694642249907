var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"entity-view-wrapper"},[_c('div',{ref:"entityViewTabs",staticClass:"entity-view-tabs-wrapper flex m-fullwidth overflow-auto white-space-nowrap"},[_c('div',{staticClass:"tab tab-entity",class:{
        'tab-active tab-entity-active': _vm.activeTab === 'overview',
      },on:{"click":function($event){return _vm.handleActiveTab('overview')}}},[_c('div',{staticClass:"entity-tab-text"},[_vm._v(" Overview ")])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
        _vm.formData.data &&
          _vm.formData.data.regulatoryCompliance &&
          _vm.formData.data.regulatoryCompliance.length
      ),expression:"\n        formData.data &&\n          formData.data.regulatoryCompliance &&\n          formData.data.regulatoryCompliance.length\n      "}],staticClass:"tab tab-entity",class:{
        'tab-active tab-entity-active':
          _vm.activeTab === 'regulatory-compliance',
      },on:{"click":function($event){return _vm.handleActiveTab('regulatory-compliance')}}},[_c('div',{staticClass:"entity-tab-text"},[_vm._v(" Regulatory Compliance ")])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
        _vm.formData.data &&
          _vm.formData.data.banks &&
          _vm.formData.data.banks.length
      ),expression:"\n        formData.data &&\n          formData.data.banks &&\n          formData.data.banks.length\n      "}],staticClass:"tab tab-entity",class:{ 'tab-active tab-entity-active': _vm.activeTab === 'banks' },on:{"click":function($event){return _vm.handleActiveTab('banks')}}},[_c('div',{staticClass:"entity-tab-text"},[_vm._v(" Banks ")])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
        _vm.formData.data &&
          _vm.formData.data.paymentSystems &&
          _vm.formData.data.paymentSystems.length
      ),expression:"\n        formData.data &&\n          formData.data.paymentSystems &&\n          formData.data.paymentSystems.length\n      "}],staticClass:"tab tab-entity",class:{
        'tab-active tab-entity-active': _vm.activeTab === 'payment-systems',
      },on:{"click":function($event){return _vm.handleActiveTab('payment-systems')}}},[_c('div',{staticClass:"entity-tab-text"},[_vm._v(" Payment Service ")])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
        (_vm.formData.data &&
          _vm.formData.data.contacts &&
          _vm.formData.data.contacts.length) ||
          (_vm.formData.data &&
            _vm.formData.data.contactsDepartments &&
            _vm.formData.data.contactsDepartments.length)
      ),expression:"\n        (formData.data &&\n          formData.data.contacts &&\n          formData.data.contacts.length) ||\n          (formData.data &&\n            formData.data.contactsDepartments &&\n            formData.data.contactsDepartments.length)\n      "}],staticClass:"tab tab-entity",class:{
        'tab-active tab-entity-active': _vm.activeTab === 'contacts',
      },on:{"click":function($event){return _vm.handleActiveTab('contacts')}}},[_c('div',{staticClass:"entity-tab-text"},[_vm._v(" Contacts ")])])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],staticClass:"flex align-center justify-center full-height"},[_c('gl-loader')],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}]},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!Object.keys(_vm.formData).length),expression:"!Object.keys(formData).length"}],staticClass:"flex align-center justify-center entity-view-content-wrapper"},[_c('div',{staticClass:"entity-empty__wrapper"},[_vm._v(" No data... ")])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(Object.keys(_vm.formData).length),expression:"Object.keys(formData).length"}]},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeTab === 'overview'),expression:"activeTab === 'overview'"}],staticClass:"entity-view-content-wrapper flex"},[_c('EntityOverviewView',{attrs:{"form-data":_vm.formData,"loading-screenshoots":_vm.loadingScreenshoots},on:{"setImageModalData":(data, i) => _vm.$emit('setImageModalData', data, i),"showViewImageModal":function($event){return _vm.$emit('showViewImageModal')}}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeTab === 'regulatory-compliance'),expression:"activeTab === 'regulatory-compliance'"}]},[(
            _vm.formData.data &&
              _vm.formData.data.regulatoryCompliance &&
              _vm.formData.data.regulatoryCompliance.length
          )?_c('div',{staticClass:"entity-view-content-wrapper"},[_c('EntityRegulatoryComplianceTable',{staticClass:"regulatory-compliance-table",attrs:{"table-data":_vm.formData.data.regulatoryCompliance}})],1):_c('div',{staticClass:"entity-view-content-wrapper flex align-center justify-center bold fs-14"},[_vm._v(" You currently have no regulation information. ")])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeTab === 'banks'),expression:"activeTab === 'banks'"}]},[(
            _vm.formData.data && _vm.formData.data.banks && _vm.formData.data.banks.length
          )?_c('div',{staticClass:"entity-view-content-wrapper"},[_c('EntityBanksTable',{staticClass:"banks-table",attrs:{"table-data":_vm.formData.data.banks}})],1):_c('div',{staticClass:"entity-view-content-wrapper flex align-center justify-center bold fs-14"},[_vm._v(" You currently have no banks. ")])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeTab === 'payment-systems'),expression:"activeTab === 'payment-systems'"}]},[(
            _vm.formData.data &&
              _vm.formData.data.paymentSystems &&
              _vm.formData.data.paymentSystems.length
          )?_c('div',{staticClass:"entity-view-content-wrapper"},[_c('EntityPaymentSystemsTable',{staticClass:"payments-systems-table",attrs:{"table-data":_vm.formData.data.paymentSystems}})],1):_c('div',{staticClass:"entity-view-content-wrapper flex align-center justify-center bold fs-14"},[_vm._v(" You currently have no payment service. ")])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeTab === 'contacts'),expression:"activeTab === 'contacts'"}],staticClass:"entity-view-content-wrapper"},[_c('div',{staticClass:"entity-view-overview-wrapper fullwidth contacts-people-departments-wrap"},[_c('div',{staticClass:"fs-16 bold-600 pa-4 pb-3"},[_vm._v(" People ")]),(
              _vm.formData.data &&
                _vm.formData.data.contacts &&
                _vm.formData.data.contacts.length
            )?_c('div',[_c('EntityContactsTable',{staticClass:"contacts-people-table",attrs:{"table-data":_vm.formData.data.contacts}})],1):_c('div',{staticClass:"entity-view-content-contract-department-wrapper flex align-center justify-center bold fs-14"},[_vm._v(" You currently have no contacts. ")]),_c('hr',{staticClass:"grey-f-5-hr"}),_c('div',{staticClass:"fs-16 bold-600 pa-4 pb-3"},[_vm._v(" Departments ")]),(
              _vm.formData.data &&
                _vm.formData.data.contactsDepartments &&
                _vm.formData.data.contactsDepartments.length
            )?_c('div',[_c('EntityContactsDepartmentsTable',{staticClass:"contacts-departments-table",attrs:{"table-data":_vm.formData.data.contactsDepartments}})],1):_c('div',{staticClass:"entity-view-content-contract-department-wrapper flex align-center justify-center bold fs-14"},[_vm._v(" You currently have no Departments. ")])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }